import React from 'react'

import * as articleShareStyles from './../styles/TemplateArticle.module.scss'
import * as styles from './../styles/Video.module.scss'

import ReactPlayer from 'react-player'
import AuthMarkdown from '../components/AuthMarkdown'
import Breadcrumbs from '../components/Breadcrumbs'
import LayoutRoot from '../components/LayoutRoot'
import EmptyPlayerIcon from '../icons/emptyplayer.inline.svg'
import { formatDate } from '../utils/format'
interface VideoProps {
  pageContext: {
    video: Video
  }
}

const Video: React.FC<VideoProps> = ({ pageContext: { video } }) => {
  const breadcrumbs = [
    {
      name: '视频培训',
      path: '/video'
    },
    {
      name: '视频详情'
    }
  ]
  return (
    <LayoutRoot noNavigator>
      <div className={articleShareStyles.articleWrapper}>
        <div className={articleShareStyles.breadWrapper}>
          <Breadcrumbs data={breadcrumbs} />
        </div>
        <div className={articleShareStyles.articleTitle}>{video?.title}</div>
        <div className={articleShareStyles.divider} />
        <div className={styles.dateTime}>{formatDate(video.videoDate)}</div>
        {!video ? (
          <EmptyPlayerIcon />
        ) : (
          <>
            <div className={styles.videoPlayerContainer}>
              <ReactPlayer width={'100%'} playing={true} controls url={video.videoLink} />
            </div>
            {video?.content?.data?.content && (
              <div className={articleShareStyles.contentWrapper}>
                <AuthMarkdown content={video.content.data.content} />
              </div>
            )}
          </>
        )}
      </div>
    </LayoutRoot>
  )
}

export default Video
